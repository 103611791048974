import React from 'react';

import { FaGithub, FaLinkedin, FaDiscord } from 'react-icons/fa';

// import { TypeAnimation } from 'react-type-animation';

// import { motion } from 'framer-motion';

// import { fadeIn } from '../variants';

const Banner = () => {
  return (
    <section className="section" id='home'>
      <div className='flex flex-col md:flex-row container mx-auto'>
        <div className='flex md:w-1/2'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <h1 className='text-[55px] font-bold leading-[0.8] lg-text-[110px]' >
              SANDEEP <span>PARIMI</span>
            </h1>
            <div className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className="mr-4">I am a </span>
              {/* <TypeAnimation sequence={[
                'Blockchain',
                2000,
                'Web3',
                2000,
                'Full Stack',
                2000,
                'React',
                2000,
              ]}
                speed={50}
                className='text-sky-400'
                wrapper='span'
                repeat={Infinity}
              /> */}
            </div>
            <h2 className='text-3xl font-bold'>Blockchain (Web3) Developer...</h2>
            <p className='mb-8 max-w-lg mx-auto lg:mx-0'>I have a passion in latest tech especially Blockchain technologies which helps me to identify client's pain points and develop tailored solutions that leverage the power of decentralized technologies to improve efficiency, security, and transparency. Working experience over 6+ years experience in plethora of projects as Free Lancer & active Team player in Web Dev, Blockchain dev by building and maintaining strategic relationships with potential clients. </p>
          

            <div className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
              {/* <button className='btn btn-lg' href=''>Contact Me</button> */}
              <a href='https://www.linkedin.com/in/sandeep-parimi' target='_blank' className='text-gradient btn-link'>Contact Me</a>
            
            </div>
          </div>
        </div>

        <div className='flex flex-col md:w-1/2 items-center self-center text-[30px]'>
          <div className='flex mb-5'>
          <a className='mr-10' href="https://github.com/sandy13869" target='_blank'><FaGithub /></a>
          <a className='mr-10' href="https://www.linkedin.com/in/sandeep-parimi/" target='_blank' ><FaLinkedin /></a>
          <a href="https://discordapp.com/users/958362271929737256" target='_blank' ><FaDiscord /></a>
          </div>
          <div className='w-auto md:w-[450px]'>
            <img src="https://shandyeth.online/assets/sam_pink.png" alt='test' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
