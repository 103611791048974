import React from 'react';

import { FaEnvelope } from 'react-icons/fa';

//images
// import Logo from '../assets/logo.svg';

const Header = () => {
  return (
    <header className='py-8'>
    <div className="container mx-auto">
      <div className='flex justify-between items-center'>
        <a href="/">
          <img className='flex w-[175px]' src="https://shandyeth.online/assets/sandy_logo.png" alt="" />
          {/* <img src="https://shandyeth.online/assets/sandy_bld.svg" alt="" /> */}
        </a>
        <button className="btn btn-sm"> 
        <a href='mailto:andy.parimi@gmail.com' className=''><FaEnvelope /></a>
          </button>
      </div>
    </div>
  </header>);
};

export default Header;
