import React from 'react';


const Work = () => {
  const education = [
    {
      course : 'Master of Business Administration (MBA)',
      institution: 'Jawaharlal Nehru Technological University (JNTUK), India',
      specialization: 'Human Resource & Marketing Management',
      year: '2016',
      gpa:'75%'
    },
    {
      course : 'Bachelor Of Technology (B.Tech)',
      institution : 'Jawaharlal Nehru Technological University (JNTUK), India',
      specialization:'Information Technology',
      year: '2013',
      gpa:'68%'
    }
  ];
  return (
    <section id="work" className="section">
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          <div
            className='flex-1 mix-blend-lighten mb-12 lg:mb-0'
            // style={{
            // backgroundImage: 'url(https://shandyeth.online/assets/sam_skills.png)',
            // backgroundSize: 'cover',
            // backgroundPosition: 'top',
            //   backgroundRepeat: 'no-repeat',
            // opacity:'0.1',
            // width: '40%'
            // }}
          >
             
            <h2 className='h2 text-accent mb-6 font-bold'> What I Have...</h2>
            <h3 className='h3 max-w-[455px] mb-16 tracking-wider'>
 <span className='text-sky-400'>Prime Skills: </span>
Solidity, Web3, JavaScript, Ethereum, Polygon, ReactJS, IPFS, GoLang, Hardhat, Foundry, Fullstack DApp, DeFi (Web App), SQL ...
            </h3>
            <button className='btn btn-sm'>
              <a href="https://www.linkedin.com/in/sandeep-parimi/" target='_blank'>See More...</a>
            </button>
          </div>

          <div className='flex-1'>
            <h2 className='h2 text-accent mb-6 font-bold'> What I Study...</h2>
            <div>
              {education.map((edu, index) => {
                const { course, institution, specialization, year, gpa} = edu;
                return (
                  <div className='border-b border-white/20 h-[220px] md:h-[146px] mb-[38px] flex'>
                    <div key={index}>
                      <div className='max-w-[500px]'>
                        <h4 className='text-[17px] tracking-wider font-primary font-semibold my-2 text-sky-400'>{ course}</h4>
                      </div>
                      <div>{ specialization} ({year})</div>
                      <div>{ institution}</div>
                    </div>
                    <div className='ml-5'>{ gpa}</div>
                  </div>
                );
              })}
            </div>
          </div>

        </div>
    </div>
    </section>
  );
};

export default Work;
