import React from 'react';

// import { useInView } from 'react-intersection-observer';

const About = () => {
  // const [ref, inView] = useInView({
  //   threshold: 0.5,
  // });
  return (
    <sectrion id="about" className="section">
      <div className='container mx-auto'>
        <div className='flex flex-col md:flex-row '>
          <div className='flex-1 bg-contain mix-blend-lighten bg-top md:p-10 md:w-2/5 w-full'>
            <img className='flex' src="https://shandyeth.online/assets/skills2.png" alt="" />
          </div>
          <div className='flex md:w-3/5 w-full flex-col'>
            <div className='flex flex-col'>
              <h2 className='h2 text-accent my-6 font-bold'>What i can...</h2>
              <h3>I am a Blockchain (web3) Full Stack web app developer with over 6 years of IT experience.</h3>
              <p>
                More about work
              </p>
              <div className='flex ml-[30px]'>
              <ul className="list-disc">
                <li>Works as a Blockchain (Web3) Developer, specializing in React, web, and mobile application 
development. 
</li>
                <li>Implement decentralized applications (DApps) and decentralized finance (DeFi) solutions 
on the blockchain using Solidity Smart Contracts. </li>
                <li>Develop expertise in working with Non-Fungible Tokens (NFTs), including ERC-20, ERC-1155 
and Compatible EVM, web3 wallets like Coinbase and Metamask. 
                  </li>
                  <li>Integrate Web3 technologies, such as EthersJS, into React (web and mobile) applications 
                    to interact with the blockchain (Ethereum, Polygon chain). </li>
                  <li>
                    Actively participate in code reviews, testing, and debugging processes to ensure the quality 
and stability of the developed solutions. Communicate and coordinate with crossfunctional teams, including designers, product managers, and backend developers, to 
deliver comprehensive blockchain-based applications and features. 
                  </li>
                  <li>
                     Stay updated with the latest developments in blockchain technologies and tools, and 
continuously enhance skills and knowledge in the field. Technical needs completed by 
building and maintaining strategic relationships with potential clients
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className='flex'>
              <div className='flex flex-col'>
                <div className='text-[40px] font-tertiary text-gradient'>
                  {inView ? <CountUp start={0} end={6} duration={3} /> : null}
                  
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Years of <br />
                  Experience
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </sectrion>
  );
};

export default About;
