import React from 'react';

import CountUp from 'react-countup';

// import { useInView } from 'react-intersection-observer';

const services = [
  {
    name: 'Asset On Chain Technologies Limited, Hong Kong',
    time: '[ Jun, 2022 - Present ]',
    description: 'Working experience with React, web & mobile applications, Full Stack development and implementation of DApps & Defi on blockchain with Solidity-Smart Contracts (deployment & optimization), NFT, Web3, EthersJS, ThreeJS, Ethereum, Polygon Network, Web3 Wallets like Metamask & Coinbase Custom Functionalities. Being active team player in the development of Solidity smart contracts for ERC 20, ERC 1155, ERC 721- NFT, Defi Modules, Web3 DApps, EVM, Production Phase with Hardhat, Truffle & React Frameworks and using Remix & VS Code IDE for industrial & Organizational requirements.',
    link: 'test link',
  },
  {
    name: 'Sr. Consultant with Startups, SMEs, (USA, UK, UAE, etc)',
    time: '[ Mar, 2020 - May, 2022 ]',
    description: 'Working experience in development and implementation of new Blockchain technologies (Smart Contracts, Web3, Custom Functionalities, Truffle, Hardhat, EVM) with UI support in React JS & Python Frontend, AWS, Scrum and completed by building and maintaining strategic relationships with potential clients from past few years.',
    link: 'test link',
  },
  {
    name: 'Two Power N Technologies Pvt Ltd, India',
    time: '[ Nov, 2016 - Feb, 2020 ]',
    description: 'As a Web Developer/Designer Full Stack roles are very precise and had to deal with our global client’s goals, make their requirements into a visualize entity on web environment by utilizing latest software trends, dealt with diverse fields like Gaming, Real-estate, E-commerce and various Service sectors. As a management resource do organize project presentations, people management, client meetings follow-up (requirements & Review meets), training, induction programs and project end to end activities including attending workshops, company meetings & industry events on behalf of the reputed client/organization.',
    link: 'test link',
  },
];

const stats = [
  {
    number: 6,
    task: 'Years & Nations',
  },
  {
    number: 20,
    task: 'Projects',
  },
  {
    number: 10,
    task: 'Clients',
  },
  {
    number: 3,
    task: 'Organizations',
  },
];

const Services = () => {
// const [ref, inView] = useInView({
//     threshold: 0.5,
//   });
  return (
    <section id="services" className="section">
      <div className='container mx-auto'>
        <div>
          <div className='flex-1'>
            <h2 className='h2 text-accent my-6 font-bold'> What i did...</h2>
            {services.map((item, index) => (
              <div key={index}>
                <h4 className='text-xl font-bold mb-2'>{item.name}<span className='text-sky-400'>  { item.time} </span></h4>
                <p className='flex py-1'>{item.description}</p>
              </div>
            ))}
          </div>
          
          <div className='flex w-auto my-6 justify-between w-auto'>
          {stats.map((stat, index) => (  
            <div className='flex flex-col'>
                <div className='flex justify-center text-2xl md:text-[40px] text-gradient'>
                  <CountUp start={0} end={stat.number} duration={3} />+
                </div>
                <div className='font-primary text-sm tracking-[2px] text-[10px] md:text-base'>
                  {stat.task} 
                </div>
            </div>))}

            </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
